//
// Menu for small screens
//
(function () {
  var menuButton = document.querySelector('#menu-button');
  var menuList = document.querySelector('#picture-only-link');
  var languagesButton = document.querySelector('#languages-button');
  var languagesList = document.querySelector('#languages-menu');

  menuButton.addEventListener('click', function () {
    if (languagesList.style.display === 'block') {
      languagesList.style.display = 'none';
      languagesButton.classList.toggle('active');
    }

    if (menuList.style.display !== 'block') {
      menuList.style.display = 'block';
    } else {
      menuList.style.display = 'none';
    }

    menuButton.classList.toggle('active');
  });

  languagesButton.addEventListener('click', function () {
    if (menuList.style.display === 'block') {
      menuList.style.display = 'none';
      menuButton.classList.toggle('active');
    }
    if (languagesList.style.display !== 'block') {
      languagesList.style.display = 'block';
    } else {
      languagesList.style.display = 'none';
    }
    languagesButton.classList.toggle('active');
  });
})();

//
// Share buttons
// FB, Twitter and copying a link
//
(function () {
  if (!document.querySelector('#share-buttons')) return;

  var fBButton = document.querySelector('#fb-button');
  var linkButton = document.querySelector('#link-button');

  linkButton.addEventListener('click', function () {
    var tempElement = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(tempElement);
    tempElement.value = text;
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    var messageElement = document.getElementById('social-copied-message');
    messageElement.innerText = 'Link copied!';
    messageElement.style.display = 'inline-block';
    setTimeout(function () {
      messageElement.style.display = 'none';
    }, 4000);
  });

  fBButton.addEventListener('click', function () {
    const link = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(link, 'newwindow', 'width=500,height=350');
  });
})();

//
// Language Variety switcher
// Navigate using JS so that bots don't follow the link
//
(function () {
  if (!document.querySelector('#english-lv-selector')) return;

  var usButton = document.querySelector('#lv-switcher-us-english');
  var ukButton = document.querySelector('#lv-switcher-uk-english');

  usButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'us-english');

    window.location.href = updatedUrl.href;
  });
  ukButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'uk-english');

    window.location.href = updatedUrl.href;
  });

  // Check if the element with id 'lv-switcher-uk-english' has the 'selected' class
  // If so update the URL to reflect the page state
  if (ukButton && ukButton.classList.contains('selected')) {
    // Add or modify URL parameter here
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('lv', 'uk-english');

    // Update the browser URL (without reloading the page)
    window.history.pushState({}, '', currentUrl);
  }
})();
